<template>
  <div :style="{ background: backgroundColor }">
    <beautiful-chat
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :close="closeChat"
      :colors="colors"
      :is-open="isChatOpen"
      :message-list="messageList"
      :message-styling="messageStyling"
      :new-messages-count="newMessagesCount"
      :on-message-was-sent="onMessageWasSent"
      :open="openChat"
      :showLauncher="false"
      :participants="participants"
      :show-close-button="true"
      :show-emoji="false"
      :show-file="false"
      :show-typing-indicator="showTypingIndicator"
      :show-edition="false"
      :show-deletion="false"
      :disableUserListToggle="true"
      :title="title"
      :placeholder="$t('chat.placeholder')"
      :title-image-url="titleImageUrl"
      :disable-user-list-toggle="false"
    >
      <template v-slot:text-message-body="{ message }">
        <div class="sc-message--text-content" v-html="message.data.text"></div>
      </template>
    </beautiful-chat>
    <img
      @click="openChat()"
      src="@/assets/ic_comment.svg"
      alt=""
      class="ic_comment"
    />
  </div>
</template>

<script>
import availableColors from "@/utils/colors";
import { getService } from "@/api/index";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      participants: [
        {
          id: "support",
          name: "support",
          imageUrl: require("@/assets/ic_customer_102.svg"),
        },
      ],
      titleImageUrl: require("@/assets/logo_tryhelp.svg"),
      messageList: [],
      newMessagesCount: 0,
      isChatOpen: false,
      showTypingIndicator: "",
      colors: null,
      availableColors,
      chosenColor: null,
      alwaysScrollToBottom: true,
      messageStyling: true,
      userIsTyping: false,
    };
  },
  computed: {
    ...mapState(["logoName"]),
    linkColor() {
      return this.chosenColor === "dark"
        ? this.colors.sentMessage.text
        : this.colors.launcher.bg;
    },
    backgroundColor() {
      return this.chosenColor === "dark" ? this.colors.messageList.bg : "#fff";
    },
  },
  created() {
    this.setColor("blue");
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const list = localStorage.getItem("messageList");
      if (list) {
        this.messageList = JSON.parse(list);
      } else {
        this.messageList = [
          {
            type: "text",
            author: `support`,
            id: 1,
            data: {
              text: this.$t("chat.defaultChat"),
            },
            suggestions: this.$t("chat.defaultSuggestions").map((res) => {
              return res.replace("{logoName}", this.logoName);
            }),
          },
        ];
      }
    },
    sendMessage(text) {
      return new Promise((resolve, reject) => {
        let messageId = localStorage.getItem("messageId");
        this.handleTyping("text");
        let query = this.$route.query||{};
        const domain = window.location.host;
        if(!this.$route.query.utm_source){
        switch (true) {
          case domain.includes('soouya'):
            query.utm_source = 'soouya_paypal'
            break;
        }
        }
        getService({ word: text, id: messageId, ...query })
          .then(async (res) => {
            if (!res.obj) {
              res.obj = {
                data: [this.$t("chat.errorChat")],
              };
            }
            if (res.obj.id && !localStorage.getItem("messageId")) {
              localStorage.setItem("messageId", res.obj.id);
            }
            if (res.obj.data.length > 0) {
              for (let index = 0; index < res.obj.data.length; index++) {
                if (res.obj.data.length > index) {
                  this.handleTyping("text");
                }
                await this.delay(1000);
                const text = res.obj.data[index];
                let suggestions =
                  res.obj.data.length - 1 === index ? res.obj.suggestions : [];
                this.sendWasSent(text, suggestions);
              }
            }
            resolve();
          })
          .catch(() => {
            this.sendWasSent(this.$t("chat.errorChat"), []);
            reject();
          });
      });
    },
    handleTyping(text) {
      this.showTypingIndicator =
        text.length > 0
          ? this.participants[this.participants.length - 1].id
          : "";
    },
    async onMessageWasSent(message) {
      this.messageList = [
        ...this.messageList,
        Object.assign({}, message, { id: Math.random() }),
      ];
      localStorage.setItem("messageList", JSON.stringify(this.messageList));
      if (message.author === "me") {
        const defaultChat = this.$t("chat.defaultListChat");
        let next = {
          checked: true,
          text: "",
        };
        Object.keys(defaultChat).forEach((key) => {
          let keyValue = key.replace("{logoName}", this.logoName);
          if (keyValue === message.data.text) {
            next.checked = false;
            next.text = defaultChat[key].map((res) => {
              return res.replace(/{logoName}/g, this.logoName).replace(/TryHelp/gi, this.logoName);
            });
          }
        });
        if (next.checked) {
          this.sendMessage(message.data.text);
        } else {
          for (let index = 0; index < next.text.length; index++) {
            if (next.text.length > index) {
              this.handleTyping("text");
              await this.delay(500);
            }
            await this.delay(1000);
            const text = next.text[index];
            this.sendWasSent(text, []);
          }
        }
      }
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    sendWasSent(text, suggestions = []) {
      this.newMessagesCount = this.isChatOpen
        ? this.newMessagesCount
        : this.newMessagesCount + 1;
      this.showTypingIndicator = "";
      let items = {
        author: "support",
        type: "text",
        id: Math.random(),
        data: { text: text },
      };
      if (suggestions.length > 0) {
        items.suggestions = suggestions;
      }
      this.onMessageWasSent(items);
    },
    openChat(email) {
      this.isChatOpen = true;
      this.newMessagesCount = 0;
      if (email) {
        localStorage.clear();
        this.init();
        this.$nextTick(async () => {
          const defaultSuggestions = 'unsubscribe';
          this.messageList.push({
            type: "text",
            author: `me`,
            id: new Date().getTime(),
            data: {
              text: defaultSuggestions,
            },
            suggestions: [],
          });
          await this.sendMessage(defaultSuggestions);
          this.messageList.push({
            type: "text",
            author: `me`,
            id: new Date().getTime() + 1,
            data: {
              text: email,
            },
            suggestions: [],
          });
          await this.sendMessage(email);
          localStorage.setItem("messageList", JSON.stringify(this.messageList));
        });
      }
      // this.$amplitude.getInstance().logEvent("openChat");
    },
    closeChat() {
      this.isChatOpen = false;
    },
    setColor(color) {
      this.colors = this.availableColors[color];
      this.chosenColor = color;
    },
  },
};
</script>

<style>
body {
  padding: 0px;
  margin: 0px;
}

* {
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
}

.demo-description {
  max-width: 500px;
}

.demo-description img {
  max-width: 500px;
}

.demo-test-area {
  width: 300px;
  box-sizing: border-box;
}

.demo-test-area--text {
  box-sizing: border-box;
  width: 100%;
  margin: 0px;
  padding: 0px;
  resize: none;
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
  background: #fafbfc;
  color: #8da2b5;
  border: 1px solid #dde5ed;
  font-size: 16px;
  padding: 16px 15px 14px;
  margin: 0;
  border-radius: 6px;
  outline: none;
  height: 150px;
  margin-bottom: 10px;
}

.demo-monster-img {
  width: 400px;
  display: block;
  margin: 60px auto;
}

.text-center {
  text-align: center;
}

.colors a {
  color: #fff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 10px;
}

.toggle a {
  text-decoration: none;
}

.messageStyling {
  font-size: small;
}
.sc-launcher {
  width: 50px !important;
  height: 50px !important;
}
.sc-open-icon {
  right: 18px !important;
  bottom: 20px !important;
}

@media (min-width: 761px) {
  .sc-message--avatar {
    padding-top: 6px;
  }
  .sc-message--text-content {
    padding: 3px 0;
    font-weight: 500;
  }
  /* .sc-message {
  width: 350px !important;
} */
  .sc-message-list {
    padding: 15px 0 !important;
  }
  .sc-header {
    min-height: 60px !important;
    padding: 0 !important;
  }
  .sc-header--img {
    height: 44px !important;
    padding: 0 0 0 10px !important;
  }
  .sc-message--content.sent {
    margin-right: 10px;
  }
}
.ic_comment {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 1;
}
@media (max-width: 760px) {
  .ic_comment {
    right: 14px;
  }
  .sc-message--avatar {
    padding-top: 4px;
  }
  .customize-warp {
    width: 86% !important;
  }
  .sc-header--img {
    width: 40px;
    padding-right: 0 !important;
  }

  .sc-message--text-content {
    padding: 3px 0;
    font-weight: 500;
  }
  .sc-message {
    width: 94% !important;
  }
}
.sc-message--text {
  padding: 5px 10px !important;
}
.sc-message--avatar {
  align-self: flex-start !important;
  margin-right: 5px !important;
}
.sc-message--content.sent {
  margin-right: 10px;
}
.sc-user-input--text {
  /* padding: 0 !important;
  max-height: 38px !important;
  margin: 18px; */
  max-height: 60px !important;
}
.sc-user-input--text::-webkit-scrollbar {
  width: 5px;
}

.sc-user-input--text::-webkit-scrollbar-thumb {
  border-radius: 999px;
}

.sc-user-input--text::-webkit-scrollbar-track {
  background: transparent;
}

.sc-user-input--text::-webkit-scrollbar-thumb {
  /* min-height: 3px; */
  height: 4px;
  background-clip: content-box;
  background: #00000080;
}

.sc-user-input--text::-webkit-scrollbar-corner {
  background: transparent;
}
.sc-user-input--text::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.sc-message--content.sent .sc-message--text {
  max-width: calc(100% - 60px) !important;
}
.sc-message--content.received .sc-message--text {
  margin-right: 30px !important;
}
</style>
